<template>
    <div
        class="badge rounded-full flex flex-row gap-1 justify-center items-center h-fit"
        :class="[
            withBorder && 'border',
            `bg-${bgColor} border-${borderColor} text-${textColor}`,
            sizeClass
        ]"
        data-testid="chargemap-badge"
    >
        <IconDot
            v-if="indicator"
            :class="[`text-${indicatorColor}`]"
            data-testid="indicator"
        />
        <div v-if="$slots.icon" :class="iconClass">
            <slot name="icon" />
        </div>
        <span :class="textClass" class="font-medium">
            <slot />
        </span>
    </div>
</template>

<script lang="ts" setup>
import { computed, type PropType } from 'vue'
import {
    BadgeBGColorB2B,
    BadgeBGColorB2C,
    BadgePaddingB2B,
    BadgePaddingB2C,
    BadgeTextColorB2B,
    BadgeTextColorB2C,
    BadgeType
} from '../../../interfaces/UI'
import IconDot from '../../icons/IconDot.vue'

const props = defineProps({
    type: {
        type: String as PropType<BadgeType>,
        default: BadgeType.INFO
    },
    isB2B: {
        type: Boolean,
        default: true
    },
    size: {
        type: String as PropType<
            'default' | 'extra-small' | 'small' | 'large' | 'extra-large'
        >,
        default: 'default'
    },
    indicator: {
        type: Boolean,
        default: false
    },
    color: {
        type: String,
        default: undefined
    },
    withBorder: {
        type: Boolean,
        default: false
    },
    customIconClass: {
        type: String,
        default: undefined
    },
    customTextSize: {
        type: String,
        default: undefined
    },
    disabled: {
        type: Boolean,
        default: false
    }
})

const sizeClass = computed(() => {
    switch (props.size) {
        case 'extra-small':
            return props.isB2B ? BadgePaddingB2B.XS : BadgePaddingB2C.XS
        case 'small':
            return props.isB2B ? BadgePaddingB2B.SMALL : BadgePaddingB2C.SMALL
        case 'large':
            return props.isB2B ? BadgePaddingB2B.LARGE : BadgePaddingB2C.LARGE
        case 'extra-large':
            return props.isB2B ? BadgePaddingB2B.XL : BadgePaddingB2C.XL
        default:
            return props.isB2B ? BadgePaddingB2B.MEDIUM : BadgePaddingB2C.MEDIUM
    }
})

const textClass = computed(() => {
    let baseClass = ''

    if (props.customTextSize) {
        baseClass = props.customTextSize
    } else {
        switch (props.size) {
            case 'extra-small':
            case 'small':
                baseClass = 'text-2xs'
                break
            case 'large':
                baseClass = 'text-sm'
                break
            case 'extra-large':
                baseClass = 'text-md'
                break
            default:
                baseClass = 'text-xs'
        }
    }

    return props.disabled ? `${baseClass} text-gray-disabled` : baseClass
})

const bgColor = computed(() => {
    if (props.disabled) {
        return props.isB2B ? BadgeBGColorB2B.NEUTRAL : BadgeBGColorB2C.NEUTRAL
    }

    if (props.color) {
        return `${props.color}-200`
    }

    const badgeBGColor = props.isB2B ? BadgeBGColorB2B : BadgeBGColorB2C

    switch (props.type) {
        case BadgeType.SUCCESS:
            return badgeBGColor.SUCCESS
        case BadgeType.NEUTRAL:
            return badgeBGColor.NEUTRAL
        case BadgeType.WARNING:
            return badgeBGColor.WARNING
        case BadgeType.ERROR:
            return badgeBGColor.ERROR
        default:
            return badgeBGColor.INFO
    }
})

const indicatorColor = computed(() => {
    if (props.color) {
        return `${props.color}-500`
    }
    switch (props.type) {
        case BadgeType.SUCCESS:
            return 'green-500'
        case BadgeType.NEUTRAL:
            return 'zinc-500'
        case BadgeType.WARNING:
            return 'orange-500'
        case BadgeType.ERROR:
            return 'red-500'
        default:
            return 'primary-400'
    }
})

const textColor = computed(() => {
    if (props.disabled) {
        return 'gray-disabled'
    }

    if (props.color) {
        return `${props.color}-900`
    }

    const badgeTextColor = props.isB2B ? BadgeTextColorB2B : BadgeTextColorB2C

    switch (props.type) {
        case BadgeType.SUCCESS:
            return badgeTextColor.SUCCESS
        case BadgeType.NEUTRAL:
            return badgeTextColor.NEUTRAL
        case BadgeType.WARNING:
            return badgeTextColor.WARNING
        case BadgeType.ERROR:
            return badgeTextColor.ERROR
        default:
            return badgeTextColor.INFO
    }
})

const borderColor = computed(() => {
    if (props.color) {
        return `${props.color}-300`
    }
    switch (props.type) {
        case BadgeType.SUCCESS:
            return 'green-300'
        case BadgeType.NEUTRAL:
            return 'zinc-300'
        case BadgeType.WARNING:
            return 'orange-300'
        case BadgeType.ERROR:
            return 'red-300'
        default:
            return 'primary-200'
    }
})

const iconClass = computed(
    () =>
        (!props.disabled && props.customIconClass) ||
        'w-3 h-3 text-gray-disabled'
)
</script>
